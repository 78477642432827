window.__auth_wrapper_version = "0.0.8";

const randomString = function(length) {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for(let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
};

//[prod,preprod,staging,dev]
const NSS_AUTH0_CONNECTION_IDS = ['con_b5LtM8AS3YhbKWqy', 'con_JzYX2KjsNvwLLyJI', 'con_H1pf3eY4nBJsdwU4', 'con_Vu1GtCQdBJf7eRXL'];

const getAuth0ReturnToUrlForNssConnection = () => {
    const nssLogoutUrl = process.env.REACT_APP_NSS_LOGOUT_URL;
    const appUrl = window.location.origin;
    const returnToUrl = `${nssLogoutUrl}?post_logout_redirect_uri=${appUrl}`;
    return returnToUrl;
};

let succeededOnce = false;

class Auth {
    constructor(opts, {baseUrl}){
        if(!opts.clientID) throw new Error("Missing clientID from opts");
        if(!opts.audience) throw new Error("Missing audience from opts");

        this.auth = new window.auth0.WebAuth(Object.assign({
            domain: "netapp-cloud-account.auth0.com",
            redirectUri: window.location.origin,
            responseType: 'token id_token',
            scope: 'openid profile email cc:netapp-internal cc:customer-stories cc:feature-spotlight cc:admin:tenancy cc:admin:subscription-whitelist cc:usage-dashboard cc:admin:audit cc:admin:errora cc:monitoring:audit cc:monitoring:internal cc:admin:sales-content cc:federation-support cc:support gradual:deploy',
            leeway: 30
        }, opts));

        this.baseUrl = baseUrl;
        this.loginRedirect = this.loginRedirect.bind(this);
        this.refreshSso = this.refreshSso.bind(this);
        this.logout = this.logout.bind(this);
        this.userConnectionId = undefined;
    }

    loginRedirect(auth0Opts, paramsForLoginPage, paramsForUI){
        const extra = paramsForLoginPage ? `?paramsForLoginPage=${window.btoa(JSON.stringify(paramsForLoginPage))}` : "";
        this.auth.authorize(Object.assign({state: window.btoa(JSON.stringify(Object.assign({rand: randomString(30)}, paramsForUI))), redirectUri: `${window.location.origin}/${extra}`}, auth0Opts));
    }

    testRefreshSso() {
        return new Promise((resolve, reject) => {
            this.auth.parseHash((err, authResult) => {
                if (authResult && authResult.accessToken) {
                    try {
                        authResult.paramsForUI = JSON.parse(window.atob(authResult.state));
                    } catch (e) {
                        reject(e);
                    }
                    resolve(authResult); // Success case
                } else {
                    reject(err);
                }
            });
        });
    }

    refreshSso(auth0Opts, paramsForUI, dontRedirectErrors){
        const authenticate = (err, authResult, resolve, reject, firstTime) => {
            this.userConnectionId = undefined;
            if (authResult && authResult.accessToken && authResult.idToken) {
                try {
                    authResult.paramsForUI = JSON.parse(window.atob(authResult.state));
                } catch (e) {

                }
                succeededOnce = true;
                if (authResult?.idTokenPayload) {
                    this.userConnectionId = authResult.idTokenPayload['http://cloud.netapp.com/connection_id'];
                }
                resolve(authResult); //success case
            }
            else{
                //case err == null: (happens for very specific errors - auth0 doesn't generate err obj) fill with 'unknown error'
                if (!err) err = {error: "unknown error", errorDescription: "Unknown error. Try to refresh or contact support"};

                //case 'login required': set needLogin flag to true and reject err obj. Caller should then call loginRedirect
                if (err.error === "login_required") {
                    err.needLogin = true;
                    reject(err);
                } else if (err.error === "timeout" && succeededOnce) {
                    reject(err);
                } else if(dontRedirectErrors || (firstTime && (err.errorDescription === "Nonce does not match." || err.errorDescription === "`state` does not match."))){
                    reject(err);
                } else{
                    //for all other errors - redirect to NetApp SaaS Portal Error Page with included error description
                    window.location.href = `${this.baseUrl}/error-page?error=${err.error}&error_description=${err.errorDescription}`;
                }
            }
        };

        const renewOnce = (firstTime) => {
            return new Promise((resolve, reject) => {
                this.auth.checkSession(Object.assign({
                    state: window.btoa(JSON.stringify(Object.assign({rand: randomString(30)}, paramsForUI)))
                }, auth0Opts), (err, authResult) => {
                    authenticate(err, authResult, resolve, reject, firstTime);
                });
            })
        };

        const parseHashOnce = () => {
            return new Promise((resolve, reject) => {
                this.auth.parseHash((err, authResult) => {
                    authenticate(err, authResult, resolve, reject, true)
                });
            })
        };

        return new Promise((resolve, reject) => {
            let refreshPromise = null;
            if(window.location.hash && (window.location.hash.indexOf("access_token") > 0 || window.location.hash.indexOf("error") > 0)){
                if(window.location.href.indexOf("#!/#access_token") !== -1) window.history.pushState({}, null, window.location.href.replace("#!/"));
                refreshPromise = parseHashOnce()
            } else {
                refreshPromise = renewOnce(true)
            }

            return refreshPromise.catch(err => {
                if(err.errorDescription === "Nonce does not match." || err.errorDescription === "`state` does not match."){
                    return renewOnce(false);
                } else throw err;
            }).then(resolve, reject)
        });
    }

    logout(opts){
        if (NSS_AUTH0_CONNECTION_IDS.includes(this.userConnectionId)) {
            this.auth.logout(Object.assign({returnTo: getAuth0ReturnToUrlForNssConnection(), federated: true}, opts));
        }
        else {
            this.auth.logout(Object.assign({returnTo: window.location.origin, federated: true}, opts));
        }
    }
}

export default Auth;
